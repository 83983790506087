import type { CtaBlockProps } from '@lib/queries';

import { Box } from '~/components/box';
import { Button } from '~/components/button';
import { Image } from '~/components/image';
import { StructuredText } from '~/components/structured-text';
import { Text } from '~/components/text';

export const CtaBlock = ({ title, body, image, cta }: CtaBlockProps) => (
  <Box
    alignItems="center"
    display="grid"
    gridTemplateColumns={{ sm: 'cols-1', md: 'cols-2' }}
    gap={{ sm: '5', lg: '1' }}
  >
    <Box padding="3">
      <Image {...image} />
    </Box>
    <Box gap="1" display="flex" flexDirection="column">
      <Text variant="h1">
        <StructuredText heading value={title} />
      </Text>
      {body && (
        <Text variant="body">
          <StructuredText heading value={body} />
        </Text>
      )}
      {cta && (
        <Button variant="small" href={cta.link}>
          {cta.text}
        </Button>
      )}
    </Box>
  </Box>
);
