import * as styles from './index.css';

import type { ProductBlockProps } from '@lib/queries';

import { Box } from '~/components/box';
import { Image } from '~/components/image';
import { ViewportTrigger } from '~/components/motion';
import { StructuredText } from '~/components/structured-text';
import { Text } from '~/components/text';

export const ProductBlock = ({ blocks }: { blocks: ProductBlockProps }) => {
  const len = (blocks.length % 4) as 1 | 2 | 3;
  return (
    <Box
      marginTop={{ md: '4', lg: '5' }}
      paddingY={{ lg: '5', xl: '0' }}
      display="grid"
      gridTemplateColumns={{ sm: 'cols-1', lg: `cols-${len}` }}
      alignItems="stretch"
      gap="2"
    >
      {blocks?.map(({ _key, icon, title, body }) => (
        <ViewportTrigger key={_key}>
          <Box
            display="flex"
            flexDirection="column"
            gap={icon ? '0.5' : '2'}
            className={icon ? styles.card : styles.info}
          >
            {icon && (
              <Box height={'4'} width="4" marginBottom="0.5">
                <Image {...icon} />
              </Box>
            )}
            <Text fontWeight={{ sm: 'medium', md: 'regular' }} variant={icon ? 'h3' : 'h2'}>
              <StructuredText heading value={title} />
            </Text>
            <StructuredText value={body} />
          </Box>
        </ViewportTrigger>
      ))}
    </Box>
  );
};
