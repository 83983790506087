import { useMotionValue } from 'framer-motion';
import { useEffect } from 'react';

import type { MotionValue } from 'framer-motion';

export const useMaxMotion = (value: MotionValue): MotionValue => {
  const max = useMotionValue(0);

  useEffect(() => {
    const maxValue = Math.max(value.get(), max.get());
    max.set(maxValue);
  }, [value]);

  return value;
};
