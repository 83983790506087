import clsx from 'clsx';
import { useScroll, useSpring, motion } from 'framer-motion';
import { useRef } from 'react';

import { Text } from '../text';

import * as styles from './index.css';

import { Box } from '~/components/box';
import { useMaxMotion } from '~/hooks';
import { OestrogenCurve, ProgesteroneCurve } from '~/icons';

export const Curves = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start center', 'center center', 'end center'],
  });

  // write a hook to keep value at max
  const springY = useMaxMotion(useSpring(scrollYProgress, { stiffness: 50, damping: 20, restDelta: 0.0001 }));

  return (
    <Box ref={containerRef} className={styles.curves} position="relative">
      <motion.div
        initial={{ opacity: 0 }}
        transition={{ type: 'spring', stiffness: 100, damping: 20, duration: 1 }}
        whileInView={{ opacity: 1 }}
        viewport={{ margin: '10%', once: true }}
      >
        <Box position="absolute" className={clsx(styles.oestrogen)}>
          <OestrogenCurve scrollYProgress={springY} />
          <Box padding="0.5" position="absolute" top="0" left="5" background="fyr600" borderRadius="radius-2">
            <Text color="white" variant="mono">
              Estrogen
            </Text>
          </Box>
        </Box>
        <Box position="absolute" className={clsx(styles.progesterone)}>
          <ProgesteroneCurve scrollYProgress={springY} />
          <Box padding="0.5" position="absolute" top="0" left="5" background="ultramarine600" borderRadius="radius-2">
            <Text color="white" variant="mono">
              Progesterone
            </Text>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};
