import { getConstants, getHomePage } from '@lib/sanity';
import Head from 'next/head';

import type { Constants, Home } from '@lib/queries';
import type { GetServerSideProps, Metadata } from 'next';

import { Box } from '~/components/box';
import { setAppContext } from '~/components/context';
import { CtaBlock } from '~/components/cta-block';
import { Heading } from '~/components/heading';
import { Hero } from '~/components/hero';
import { ProductBlock } from '~/components/product-block';
import { WhatsYourRhythm } from '~/components/whats-your-rhythm';
import { Wrapper } from '~/components/wrapper';

export const metadata: Metadata = {
  description:
    'Find Your Rhythm helps you understand and embrace how your hormones impact your mood, motivation, energy levels and cravings, to help you work towards your weight loss and wellbeing goals.',
  robots: {
    follow: true,
    googleBot: {
      index: true,
      follow: false,
      noimageindex: true,
      'max-image-preview': 'large',
      'max-snippet': -1,
      'max-video-preview': -1,
    },
    index: false,
    nocache: true,
  },
  title: 'Home | Find Your Rhythm',
};

const HomePage = ({ hero, highlight, productBlock, whatsYourRhythm, cta, context }: Home & { context: Constants }) => {
  setAppContext(context);

  return (
    <>
      <Head>
        <title>Find Your Rhythm</title>
        <meta
          content="Find Your Rhythm helps you understand and embrace how your hormones impact your mood, motivation, energy levels and cravings, to help you work towards your weight loss and wellbeing goals."
          name="description"
          property="og:description"
        />
        <meta
          content="index, max-image-preview=large, max-snippet=-1, max-video-preview=-1, nofollow, noimageindex"
          name="googlebot"
        />
        <meta content="follow, nocache, noindex" name="robots" />
        <meta content="Find Your Rhythm" key="title" name="title" property="og:title" />
      </Head>
      <Wrapper>
        <Hero {...hero} />
      </Wrapper>
      <Heading {...highlight} />
      <Box display={{ sm: 'none', lg: 'block' }} marginTop={{ sm: '-3', md: '2', lg: '5' }} />
      <Wrapper>
        <ProductBlock blocks={productBlock} />
      </Wrapper>
      <Wrapper>
        <WhatsYourRhythm {...whatsYourRhythm} />
      </Wrapper>
      {cta && (
        <Wrapper>
          <CtaBlock {...cta} />
        </Wrapper>
      )}
    </>
  );
};

export const getServerSideProps: GetServerSideProps<Home> = async () => {
  const homePage = await getHomePage();
  const context = await getConstants();

  return {
    props: { ...homePage, context },
  };
};

export default HomePage;
