import clsx from 'clsx';
import { motion, useScroll } from 'framer-motion';
import { times } from 'lodash';

import { Box } from '../box';
import { Text } from '../text';

import * as styles from './index.css';

import type { PropsWithChildren } from 'react';

const Row = ({ index }: { index: number }) => (
  <Box style={{ gridRow: index + 1 }} className={styles.row}>
    <Box className={styles.marker} />
    <Box className={styles.marker} />
  </Box>
);

const Stage = ({ className }: { className: string }) => {
  return (
    <motion.div
      className={clsx(className)}
      initial={{ opacity: 0 }}
      transition={{ type: 'spring', stiffness: 50, damping: 15, duration: 500 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, margin: '-50%' }}
    />
  );
};

export const Grid = ({ children }: PropsWithChildren) => (
  <Box className={styles.grid}>
    {children}

    {/* blue fills */}
    <Stage className={clsx(styles.stages, styles.oestrogenFill, styles.stage0)} />
    <Stage className={clsx(styles.stages, styles.oestrogenFill, styles.stage1)} />
    <Stage className={clsx(styles.stages, styles.oestrogenFill, styles.stage2)} />
    <Stage className={clsx(styles.stages, styles.oestrogenFill, styles.stage3)} />
    <Stage className={clsx(styles.stages, styles.oestrogenFill, styles.stage4)} />

    {/* pink fills */}
    <Stage className={clsx(styles.stages, styles.progesteroneFill, styles.stage0)} />
    <Stage className={clsx(styles.stages, styles.progesteroneFill, styles.stage1)} />
    <Stage className={clsx(styles.stages, styles.progesteroneFill, styles.stage2)} />
    <Stage className={clsx(styles.stages, styles.progesteroneFill, styles.stage3)} />
    <Stage className={clsx(styles.stages, styles.progesteroneFill, styles.stage4)} />

    {/* borders */}
    <Box className={clsx(styles.stageBorder, styles.stage0)} />
    <Box className={clsx(styles.stageBorder, styles.stage1)} />
    <Box className={clsx(styles.stageBorder, styles.stage2)} />
    <Box className={clsx(styles.stageBorder, styles.stage3)} />
    <Box className={clsx(styles.stageBorder, styles.stage4)} />
    {times(styles.rows, (index) => (
      <Row index={index} key={`row-${index}`} />
    ))}
  </Box>
);
