import { motion } from 'framer-motion';

import type { PropsWithChildren } from 'react';

export const ViewportTrigger = ({
  children,
  delay,
  ...props
}: PropsWithChildren<{ delay?: number; className?: string }>) => (
  <motion.div
    initial={{ opacity: 0 }}
    transition={{ type: 'tween', ease: 'easeOut', duration: 0.8, delay: delay ?? 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ margin: '-15%', once: true }}
    {...props}
  >
    {children}
  </motion.div>
);
