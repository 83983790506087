import type { HeroSchema } from '@lib/queries';

import { Box } from '~/components/box';
import { Button } from '~/components/button';
import { Image } from '~/components/image';
import { StructuredText } from '~/components/structured-text';
import { Text } from '~/components/text';

export const Hero = ({ title, body, cta, image }: HeroSchema) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={{ sm: 'cols-1', md: 'cols-2' }}
      gap={{ sm: '3', lg: '2' }}
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" gap="1" marginY={{ lg: '5' }} justifyContent="center">
        <Box>
          <Text lineHeight="snug" display="inline" variant="hero">
            <StructuredText heading value={title} />
          </Text>
        </Box>
        <Text color="grey" variant="body">
          {body}
        </Text>
        {cta && (
          <Button variant="small" href={cta.link}>
            {cta.text}
          </Button>
        )}
      </Box>
      <Box paddingLeft={{ lg: '3' }} paddingY={{ lg: '3' }}>
        <Image {...image} />
      </Box>
    </Box>
  );
};
