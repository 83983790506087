import clsx from 'clsx';

import { Curves } from './curves';
import { Grid } from './grid';
import * as styles from './index.css';

import type { WhatsYourRhythmProps } from '@lib/queries';

import { Box } from '~/components/box';
import { ViewportTrigger } from '~/components/motion';
import { StructuredText } from '~/components/structured-text';
import { Text } from '~/components/text';
import { Curve } from '~/icons';

const Card = ({ children, className }: { children: React.ReactNode; className: string }) => {
  return <ViewportTrigger className={className}>{children}</ViewportTrigger>;
};

export const WhatsYourRhythm = ({ title, body, stage1, stage2, stage3, stage4 }: WhatsYourRhythmProps) => {
  return (
    <Box display="flex" flexDirection="column" gap="1" alignItems="center" marginTop="2" marginBottom={'-3'}>
      <Box>
        <Curve height="4rem" color="ultramarine900" />
      </Box>
      <Text variant="h2" fontWeight={{ sm: 'medium', md: 'regular' }}>
        <StructuredText heading value={title} />
      </Text>
      <Box
        marginX={{ sm: '0', lg: '5' }}
        paddingX={{ sm: '3', lg: '5' }}
        className={styles.heading}
        display="flex"
        flexDirection="column"
        gap="1"
        alignItems="center"
      >
        <Box textAlign="center" paddingTop="1">
          <Text color="grey" variant="body">
            <StructuredText value={body} />
          </Text>
        </Box>
      </Box>
      <Grid>
        <Curves />

        {/*  Cards */}
        <Card className={clsx(styles.cards, styles.card1)}>
          <Box className={styles.cardContent}>
            <Text variant="preHeading">{stage1.preHeading}</Text>
            <Text variant="h2">{stage1.heading}</Text>
            <Text variant="body">
              <StructuredText heading value={stage1.body} />
            </Text>
          </Box>
        </Card>

        <Card className={clsx(styles.cards, styles.card2)}>
          <Box className={styles.cardContent}>
            <Text variant="preHeading">{stage2.preHeading}</Text>
            <Text variant="h2">{stage2.heading}</Text>
            <Text variant="body">
              <StructuredText heading value={stage2.body} />
            </Text>
          </Box>
        </Card>

        <Card className={clsx(styles.cards, styles.card3)}>
          <Box className={styles.cardContent}>
            <Text variant="preHeading">{stage3.preHeading}</Text>
            <Text variant="h2">{stage3.heading}</Text>
            <Text variant="body">
              <StructuredText heading value={stage3.body} />
            </Text>
          </Box>
        </Card>

        <Card className={clsx(styles.cards, styles.card4)}>
          <Box className={styles.cardContent}>
            <Text variant="preHeading">{stage4.preHeading}</Text>
            <Text variant="h2">{stage4.heading}</Text>
            <Text variant="body">
              <StructuredText heading value={stage4.body} />
            </Text>
          </Box>
        </Card>
      </Grid>
    </Box>
  );
};
