import { Grid } from '../grid';

import * as styles from './index.css';

import type { HeadingBlock } from '@lib/queries';

import { Box } from '~/components/box';
import { ViewportTrigger } from '~/components/motion';
import { StructuredText } from '~/components/structured-text';
import { Text } from '~/components/text';

export const Heading = ({ text }: HeadingBlock) => (
  <Box textAlign={'center'} className={styles.background}>
    <Grid>
      <Box gridColumn={{ sm: '1 / span 12', lg: '3 / span 8' }}>
        <ViewportTrigger>
          <Text variant="h1">
            <StructuredText heading value={text} />
          </Text>
        </ViewportTrigger>
      </Box>
    </Grid>
  </Box>
);
